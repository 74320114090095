// Add new route here — consider adding it to lists below
export const ROUTE_NAMES = {
    MAIN: 'main',
    GAME_PAGE: 'game-page',
    EXPORT_GAME_PAGE: 'export-game-page',
    WIDGET_GAME_PAGE: 'widget-game-page',
    CATEGORY: 'category',
    PUBLISHERS: 'publishers',
    ADVERTISERS: 'advertisers',
    CONFIDENTIAL: 'confidential',
    TERMS_OF_USE: 'termsofuse',
    LICENSE: 'license',
    PARTNERS_API: 'partners-api',
    CONTACTS: 'contacts',
    PROFILE: 'profile',
    SHOP: 'shop',
    INVITE: 'invite',
    ALL_CATEGORIES: 'all',
    ALL_CATEGORIES_OLD_PAGE: 'games-categories',
    TAKEDOWN_NOTICE: 'takedown-notice',
} as const

export const ROUTES_WITH_GAME_PARAM: string[] = [
    ROUTE_NAMES.GAME_PAGE,
    ROUTE_NAMES.EXPORT_GAME_PAGE,
    ROUTE_NAMES.WIDGET_GAME_PAGE,
]

export const ROUTES_WITHOUT_PORTAL_FULSCREEN_AD: string[] = [
    ROUTE_NAMES.GAME_PAGE,
    ROUTE_NAMES.EXPORT_GAME_PAGE,
    ROUTE_NAMES.WIDGET_GAME_PAGE,
]

export const ROUTES_WITH_PRELOAD_GAME_AD: string[] = [ROUTE_NAMES.EXPORT_GAME_PAGE]

export const ROUTES_ACCEPTING_NO_ADS_PARAM: string[] = [ROUTE_NAMES.EXPORT_GAME_PAGE]

export const ROUTES_HIDE_PORTAL_STICKY: string[] = [ROUTE_NAMES.EXPORT_GAME_PAGE, ROUTE_NAMES.WIDGET_GAME_PAGE]
