import { ROUTE_NAMES } from '@/router/constants'
import { LinkTarget } from '@/types'
import { RouteLocationRaw } from 'vue-router'

export const DEVELOPERS_LANDPAGE_PATH = '/developers'
export const BLOG_PATH = '/blog'

export const PRIVACY_POLICY_NAVIGATION_ITEM = {
    title: 'privacy_policy',
    link: '/confidential',
}

type NavLink = {
    // TODO: type title, link to safely use our routes/route-names
    title: string
    link: string | RouteLocationRaw
    target?: LinkTarget
    external?: boolean
}

export const NAVIGATION_LINKS: NavLink[] = [
    {
        title: 'developers',
        link: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank',
        external: true, // Do not remove. This route is not in the Vue app
    },
    {
        title: 'advertisers',
        link: '/advertisers',
    },
    {
        title: 'publishers',
        link: '/publishers',
    },
    PRIVACY_POLICY_NAVIGATION_ITEM,
    {
        title: 'contact_us',
        link: '/contacts',
    },
    {
        title: 'takedown_notice',
        link: '/takedown-notice',
    },
]

export const ALL_GAMES_LINK: NavLink = {
    title: 'all_games',
    link: { name: ROUTE_NAMES.ALL_CATEGORIES, params: { type: 'categories' } },
}

export const ALL_TAGS_LINK: NavLink = {
    title: 'all_tags',
    link: { name: ROUTE_NAMES.ALL_CATEGORIES, params: { type: 'tags' } },
}

export const ALL_SERIES_LINK: NavLink = {
    title: 'all_series',
    link: { name: ROUTE_NAMES.ALL_CATEGORIES, params: { type: 'series' } },
}

export const NAVIGATION_ALL_GAMES_LINKS: NavLink[] = [ALL_GAMES_LINK, ALL_TAGS_LINK, ALL_SERIES_LINK]

export const CUSTOM_PG_EVENTS = [
    'game_opened',
    'session_start',
    'export_timespent_3min',
    'export_timespent_1.5min',
] as const
export const DEFAULT_CLIDS = { direct: 'direct', directTest: 'direct-test' }
