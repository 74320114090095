import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import UserAvatar from '@/components_new/UserAvatar.vue'
import Typography from '@/components_new/Typography.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Balance from '@/components_new/Balance.vue'
import type { User } from '@/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAndBalance',
  props: {
    user: {},
    userBalance: {},
    isProfilePage: { type: Boolean }
  },
  setup(__props: any) {

const { t } = useI18n()

const props = __props

const userName = computed(() => `${props.user.firstName || 'Anonimous'} ${props.user.lastName || ''}`)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.left)
    }, [
      (_ctx.isProfilePage)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(Typography, {
              type: "caption_all-caps",
              class: _normalizeClass(_ctx.$style.caption),
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('gamer')), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(Typography, {
              type: "label",
              size: "l",
              accent: "",
              class: _normalizeClass(_ctx.$style.textBlock),
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(userName.value), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(Typography, {
              type: "caption_all-caps",
              class: _normalizeClass(_ctx.$style.caption),
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('my_balance')), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.textBlock)
            }, [
              _createVNode(Icon, { name: "fennek_coin_small" }),
              _createVNode(Typography, {
                type: "header",
                size: "s",
                class: _normalizeClass(_ctx.$style.balance),
                responsive: false
              }, {
                default: _withCtx(() => [
                  _createVNode(Balance, { balance: _ctx.userBalance }, null, 8, ["balance"])
                ]),
                _: 1
              }, 8, ["class"])
            ], 2)
          ], 64))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.right)
    }, [
      _createVNode(UserAvatar, {
        class: _normalizeClass(_ctx.$style.avatar),
        avatar: props.user.avatar,
        firstName: props.user.firstName,
        lastName: props.user.lastName
      }, null, 8, ["class", "avatar", "firstName", "lastName"])
    ], 2)
  ], 2))
}
}

})