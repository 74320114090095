import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href", "target"]

import { RouteLocationRaw, RouterLink } from 'vue-router'
import { computed } from 'vue'
import { LinkTarget } from '@/types'
import { useUrl } from '@/composables/url-helpers'

export interface BaseLinkProps {
    disabled?: boolean
    to: string | RouteLocationRaw
    target?: LinkTarget
    external?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLink',
  props: {
    disabled: { type: Boolean },
    to: {},
    target: {},
    external: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const { keepRequiredParams } = useUrl()

const url = computed(() => {
    if (props.external) {
        if (typeof props.to !== 'string') {
            throw new Error('External link must have a string URL')
        }
        return props.to
    }
    return keepRequiredParams(props.to)
})

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (props.external)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: url.value as string,
        target: props.target,
        class: _normalizeClass([_ctx.$style.baseLink, props.disabled ? _ctx.$style.disabled : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_1))
    : (_openBlock(), _createBlock(_unref(RouterLink), {
        key: 1,
        to: url.value,
        target: props.target,
        class: _normalizeClass([_ctx.$style.baseLink, props.disabled ? _ctx.$style.disabled : '']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click', $event)))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "target", "class"]))
}
}

})