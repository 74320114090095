import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]

import { computed, type InputTypeHTMLAttribute, ref } from 'vue'

type ValueType = T extends 'number' ? number : string


export default /*@__PURE__*/_defineComponent({
  __name: 'TextInput',
  props: {
    is: {},
    id: {},
    name: {},
    labelText: {},
    modelValue: {},
    disabled: { type: Boolean },
    withHiddenLabel: { type: Boolean },
    placeholder: {},
    value: {},
    size: {},
    type: {}
  },
  emits: ["input", "update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const size = computed(() => props.size || 'm')
const is = computed(() => props.is || 'input')
const withHiddenLabel = computed(() => !props.labelText || props.withHiddenLabel)
const isInvalid = ref(false)

const emit = __emit

const inputValue = computed(() => props.modelValue ?? props.value)

const shouldConvertToNumber = computed(() => props.type === 'number' || typeof inputValue.value === 'number')

const convertToNumber = (value: string) => (Number.isFinite(+value) ? +value : 0)

const onInput = (e: UIChangeEvent<HTMLInputElement>) => {
    isInvalid.value = !e.target.validity.valid
    const newValue = shouldConvertToNumber.value ? convertToNumber(e.target.value) : e.target.value
    emit('update:modelValue', newValue as ValueType)
    emit('input', e)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([
            _ctx.$attrs.class,
            _ctx.$style.rootLabel,
            _ctx.$style[size.value],
            {
                [_ctx.$style.disabled]: _ctx.disabled,
                [_ctx.$style.invalid]: isInvalid.value,
                [_ctx.$style.filled]: !!inputValue.value,
                [_ctx.$style.withVisibleLabel]: !withHiddenLabel.value,
            },
        ]),
    for: _ctx.id
  }, [
    (_ctx.labelText)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!withHiddenLabel.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.$style.labelText),
                "aria-hidden": "true"
              }, _toDisplayString(_ctx.labelText), 3))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.$style.labelText, _ctx.$style.animatedLabelText, { [_ctx.$style.invisible]: withHiddenLabel.value }])
          }, _toDisplayString(_ctx.labelText), 3)
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(is.value), _mergeProps({ ..._ctx.$attrs, class: undefined }, {
      id: _ctx.id,
      class: _ctx.$style.input,
      disabled: _ctx.disabled,
      name: _ctx.name,
      type: props.type,
      value: inputValue.value,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (onInput($event as UIChangeEvent<HTMLInputElement>)))
    }), null, 16, ["id", "class", "disabled", "name", "type", "value", "placeholder"]))
  ], 10, _hoisted_1))
}
}

})