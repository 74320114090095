import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import { type TriggerEvent, Tooltip as VTooltip } from 'floating-vue'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'

interface TooltipProps {
    content?: string
    shown?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Tooltip',
  props: {
    content: {},
    shown: { type: Boolean, default: undefined }
  },
  setup(__props: any) {

const props = __props
const triggers = computed((): TriggerEvent[] => {
    if (props.shown !== undefined || !props.content) {
        return []
    }
    return ['hover', 'focus']
})

const isMounted = useIsMounted()

return (_ctx: any,_cache: any) => {
  return (_unref(isMounted))
    ? (_openBlock(), _createBlock(_unref(VTooltip), {
        key: 0,
        theme: "common-tooltip",
        triggers: triggers.value,
        shown: _ctx.shown
      }, {
        popper: _withCtx(() => [
          _createVNode(Typography, {
            type: "label",
            size: "m",
            accent: true,
            responsive: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.content), 1)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["triggers", "shown"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}
}

})