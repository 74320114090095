import { ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'

export const useDynamicHover = () => {
    const itemDynamicHover = ref<HTMLElement | null>(null)

    const updateItemDynamicHoverPosition = ({
        pageX,
        pageY,
        currentTarget,
    }: {
        pageX?: number
        pageY?: number
        currentTarget: HTMLElement
    }) => {
        const itemHover = itemDynamicHover.value
        if (!itemHover) {
            return
        }
        const currentTargetRect = currentTarget.getBoundingClientRect()
        const targetCenterX = currentTarget.clientWidth / 2
        const targetCenterY = currentTarget.clientHeight / 2
        let x = currentTarget.offsetLeft
        let y = currentTarget.offsetTop
        if (pageX && pageY) {
            x = currentTarget.offsetLeft + (pageX - currentTargetRect.left - targetCenterX) / (targetCenterX / 15)
            y = currentTarget.offsetTop + (pageY - currentTargetRect.top - targetCenterY) / (targetCenterY / 1.5)
        }
        // Create new animation with spring easing
        itemHover.animate(
            [
                {
                    transform: `translate(${x}px, ${y}px)`,
                    width: `${currentTarget.clientWidth}px`,
                    height: `${currentTarget.clientHeight}px`,
                },
            ],
            {
                duration: 200,
                easing: `cubic-bezier(0.2, 0, 0.8, 1.4)`,
                fill: 'forwards',
            },
        )
    }

    const updateItemDynamicHoverPositionDebounced = useDebounceFn(updateItemDynamicHoverPosition, 100)

    return {
        itemDynamicHover,
        updateItemDynamicHoverPosition,
        updateItemDynamicHoverPositionDebounced,
    }
}
