import { Banner, BannerState } from './banner'

export class RewardedBanner extends Banner {
    private makeVisible: (() => void) | undefined

    triggerReady(callback?: () => void) {
        if (this.state !== BannerState.ready && callback) {
            this.makeVisible = callback
            this.state = BannerState.ready
        }
        this.dispatch('ready')
    }

    show() {
        if (this.makeVisible && this.state !== BannerState.showing) {
            this.triggerRendered()
            this.makeVisible()
        } else {
            throw new Error('The banner is not ready')
        }
    }
}
