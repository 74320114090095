import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = ["src", "alt"]

import { ref } from 'vue'
import Typography from '@/components_new/Typography.vue'
import Button from '@/components_new/Button.vue'

interface DialogProps {
    title: string
    description: string
    image: string
    alt: string
    isOpened: boolean
    withOutsideClickClose?: boolean
    hasCloseBtn?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalContainer',
  props: {
    title: {},
    description: {},
    image: {},
    alt: {},
    isOpened: { type: Boolean },
    withOutsideClickClose: { type: Boolean, default: true },
    hasCloseBtn: { type: Boolean, default: true }
  },
  emits: ["closeModal"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

function requestCloseModal(e: Event) {
    e.preventDefault() // do not close dialog immediately, wait until the isOpened props will be updated
    emit('closeModal')
}
function outsideClick(e: MouseEvent) {
    if (props.withOutsideClickClose && e.target === e.currentTarget) {
        requestCloseModal(e)
    }
}

const dialogOnMount = (el: unknown | null) => (el as HTMLDialogElement)?.showModal()

const scrollTop = ref(0)
function onBeforeEnter() {
    scrollTop.value = document.documentElement.scrollTop
    requestAnimationFrame(() => {
        document.body.scrollTop = scrollTop.value
        // ios safari has bug with bottom navigation pannel.
        // Sometimes it causes dialog element
        // to be shifted up after applying "overflow: hidden" on body.
        // Solution: set random positive scroll after repaint
        document.documentElement.scrollTop = 1
    })
}

function onAfterLeave() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = scrollTop.value
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    enterActiveClass: _ctx.$style.transition_enter_active,
    enterFromClass: _ctx.$style.transition_enter_from,
    leaveActiveClass: _ctx.$style.transition_leave_active,
    leaveToClass: _ctx.$style.transition_leave_to,
    appear: "",
    onBeforeEnter: onBeforeEnter,
    onAfterLeave: onAfterLeave
  }, {
    default: _withCtx(() => [
      (props.isOpened)
        ? (_openBlock(), _createElementBlock("dialog", {
            key: 0,
            ref: dialogOnMount,
            autofocus: "",
            class: _normalizeClass(_ctx.$style.dialog),
            onClick: outsideClick,
            onClose: requestCloseModal
          }, [
            (props.hasCloseBtn)
              ? (_openBlock(), _createBlock(Button, {
                  key: 0,
                  icon: "close",
                  color: "white-60",
                  visual: "ghost",
                  class: _normalizeClass(_ctx.$style.close),
                  onClick: requestCloseModal
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.dialog_wrap)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.dialog_content)
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(_ctx.$style.dialog_image),
                  src: props.image,
                  alt: props.alt
                }, null, 10, _hoisted_1),
                _createVNode(Typography, {
                  type: "header",
                  size: "s",
                  responsive: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.title), 1)
                  ]),
                  _: 1
                }),
                _createVNode(Typography, {
                  is: "p",
                  class: _normalizeClass(_ctx.$style.dialog_description),
                  size: "s",
                  responsive: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.description), 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ], 2),
              _renderSlot(_ctx.$slots, "custom-content"),
              (_ctx.$slots.actions)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.dialog_actions)
                  }, [
                    _renderSlot(_ctx.$slots, "actions")
                  ], 2))
                : _createCommentVNode("", true)
            ], 2)
          ], 34))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["enterActiveClass", "enterFromClass", "leaveActiveClass", "leaveToClass"]))
}
}

})