import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

import { computed, type ComputedRef } from 'vue'

import type {
    DailyTaskDto,
    GameTaskDto,
    RandomTaskDto,
    ReferralTaskDto,
    ShortGameType,
    TaskDto,
    TaskStatus,
    TaskType,
} from '@/types'
import Button from '@/components_new/Button.vue'
import { useI18n } from 'vue-i18n'
import Typography from '@/components_new/Typography.vue'

import Icon from '@/components_new/Icon/Icon.vue'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import { tasksTranslation } from '@/components_new/TaskItems/utils'

interface TaskProps {
    task: TaskDto
    appearance?: 'default' | 'accent'
}

type Task = {
    id: string
    status: TaskStatus
    coins: number
    description: string
    buttonLabel: string
    gameLink?: string
    // we might not need this game key anywhere
    game?: ShortGameType
    type: TaskType
}

type WidgetAppearance = {
    buttonColor: 'purple-400' | 'carbon-400'
    buttonSize: 'm' | 'l'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TaskWidget',
  props: {
    task: {},
    appearance: { default: 'default' }
  },
  emits: ["claimTask", "taskStart"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit
const { t } = useI18n({ messages: tasksTranslation })
const widgetAppearance: ComputedRef<WidgetAppearance> = computed(() => {
    if (props.appearance === 'accent') {
        return {
            buttonColor: 'purple-400',
            buttonSize: 'm',
        }
    }

    return {
        buttonColor: 'carbon-400',
        buttonSize: 'm',
    }
})

const categoriesStore = useCategoriesStore()

const { randomGameHRU } = storeToRefs(categoriesStore)

function getDailyBonusTask(task: DailyTaskDto): Task {
    const { status, coins, task_id, type } = task
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: t('claim'),
        description: t('daily_bonus_description'),
    }
}

function getReferralTask(task: ReferralTaskDto): Task {
    const {
        status,
        coins,
        task_id,
        type,
        settings: { invitations_not_claimed },
    } = task
    const coinsPerFriend = invitations_not_claimed > 1 ? Math.floor(coins / invitations_not_claimed) : coins
    const description =
        invitations_not_claimed > 1
            ? t('collect_invite_widget_many', { friends: invitations_not_claimed, coins: coinsPerFriend })
            : t('collect_invite_widget', { coins: coinsPerFriend })
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: t('claim'),
        description,
    }
}

function getRandomGameTask(task: RandomTaskDto): Task {
    const { status, coins, settings, task_id, type } = task
    const min = Math.ceil(settings.seconds_to_play / 60)
    return {
        status,
        type,
        id: task_id,
        coins,
        gameLink: `/game/${randomGameHRU.value}`,
        description: t('random_game_description', { min }),
        buttonLabel: t('play'),
    }
}

function getGameTask(task: GameTaskDto): Task {
    const {
        status,
        coins,
        settings: { game, seconds_to_play },
        task_id,
        type,
    } = task
    const min = Math.ceil(seconds_to_play / 60)
    return {
        status,
        type,
        coins,
        id: task_id,
        gameLink: `/game/${game?.hru}`,
        game,
        description: game.title,
        buttonLabel: t('button_label', { min }),
    }
}

const task = computed(() => {
    if (props.task.type === 'VISIT_EVERYDAY') {
        return getDailyBonusTask(props.task)
    }

    if (props.task.type === 'RANDOM_GAME') {
        return getRandomGameTask(props.task)
    }

    if (props.task.type === 'PLAY_GAME') {
        return getGameTask(props.task)
    }
    return getReferralTask(props.task)
})

function onClaim() {
    emit('claimTask', task.value.id)
}

function onPlay() {
    emit('taskStart', task.value.id)
}

return (_ctx: any,_cache: any) => {
  return (task.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.task)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.task_content)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.fennecs)
          }, [
            _createVNode(Icon, { name: "fennek_coin_small" }),
            _createVNode(Typography, {
              type: "header",
              size: "s",
              active: "",
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.value.coins), 1)
              ]),
              _: 1
            })
          ], 2),
          _createVNode(Typography, {
            type: "paragraph",
            size: "xs",
            class: _normalizeClass(_ctx.$style.description),
            responsive: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(task.value.description), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        (task.value.status === 'ACTIVE')
          ? (_openBlock(), _createBlock(Button, {
              key: 0,
              size: widgetAppearance.value.buttonSize,
              color: widgetAppearance.value.buttonColor,
              link: task.value.gameLink,
              onClick: onPlay
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.value.buttonLabel), 1)
              ]),
              _: 1
            }, 8, ["size", "color", "link"]))
          : (_openBlock(), _createBlock(Button, {
              key: 1,
              color: "golden",
              size: widgetAppearance.value.buttonSize,
              onClick: onClaim
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('claim')), 1)
              ]),
              _: 1
            }, 8, ["size"]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})