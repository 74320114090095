import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 28 26"
}
const _hoisted_2 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 148 32"
}

import Link from '@/components_new/Link.vue'

interface LogoProps {
    short?: boolean
    noLink?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Logo',
  props: {
    short: { type: Boolean, default: false },
    noLink: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Link, {
    disabled: _ctx.noLink,
    to: "/",
    color: "purple-500",
    class: _normalizeClass([_ctx.$style.logo, { [_ctx.$style.short]: props.short }])
  }, {
    default: _withCtx(() => [
      (props.short)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("path", {
              fill: "currentColor",
              d: "M0 21.143V4h6.54c3.611 0 6.54 2.943 6.54 6.571 0 3.629-2.929 6.572-6.54 6.572H4.265v4zM6.113 8H4.265v5.143h1.848c1.536 0 2.56-1.143 2.56-2.572C8.673 9.143 7.649 8 6.113 8m14.778-4H28v13.4c0 3.829-2.758 6.6-6.568 6.6h-5.659v-4.286h5.63c1.337 0 2.332-1 2.332-2.343v-.228H20.89c-3.61 0-6.54-2.943-6.54-6.572S17.281 4 20.891 4m-2.132 6.571c0 1.429 1.023 2.572 2.559 2.572h2.417V8h-2.417c-1.536 0-2.56 1.143-2.56 2.571"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
            _createElementVNode("path", {
              fill: "currentColor",
              d: "M0 28.484v-21.1h8.089a8.09 8.09 0 0 1 8.088 8.089A8.09 8.09 0 0 1 8.09 23.56H5.275v4.924zm7.561-16.176H5.275v6.33h2.286c1.9 0 3.165-1.407 3.165-3.166s-1.266-3.164-3.165-3.164M24.547 23.56H19.06V2.462h5.486zm19.051 0h-8.089a8.09 8.09 0 0 1-8.088-8.087 8.09 8.09 0 0 1 8.088-8.088h8.089zm-7.561-4.923h2.285v-6.33h-2.285c-1.9 0-3.166 1.407-3.166 3.165s1.267 3.165 3.166 3.165M54.64 32h-7.315v-4.923h6.963c1.864 0 3.236-1.372 3.236-3.235v-.282H47.325V7.385H52.6v11.252h4.924V7.385h5.275v16.808c0 4.502-3.447 7.807-8.159 7.807M73.838 7.385h8.792v16.492c0 4.712-3.411 8.123-8.124 8.123h-6.998v-5.275h6.963c1.653 0 2.884-1.23 2.884-2.883v-.282h-3.517a8.09 8.09 0 0 1-8.089-8.087 8.09 8.09 0 0 1 8.089-8.088M71.2 15.473c0 1.758 1.266 3.164 3.165 3.164h2.99v-6.33h-2.99c-1.899 0-3.165 1.407-3.165 3.165m30.404 8.088h-8.089a8.09 8.09 0 0 1-8.088-8.087 8.09 8.09 0 0 1 8.088-8.088h8.089zm-7.561-4.923h2.285v-6.33h-2.285c-1.9 0-3.166 1.407-3.166 3.165s1.267 3.165 3.166 3.165m16.563 4.923h-5.275V7.385h16.353c3.974 0 7.209 3.235 7.209 7.208v8.967h-5.275v-9.318a1.95 1.95 0 0 0-1.934-1.934h-1.934V23.56h-5.275V12.308h-3.869zm37.086 0h-8.088a8.09 8.09 0 0 1-8.089-8.087 8.09 8.09 0 0 1 8.089-8.088h8.088zm-7.561-4.923h2.286v-6.33h-2.286c-1.899 0-3.165 1.407-3.165 3.165s1.266 3.165 3.165 3.165"
            }, null, -1)
          ])))
    ]),
    _: 1
  }, 8, ["disabled", "class"]))
}
}

})