import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList } from "vue"

import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import TasksWidget from '@/components_new/TaskItems/TasksWidget.vue'
import PromoSignInHorizontalShort from '@/components_new/PromoItems/components/PromoSignInHorizontalShort.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/constants/general'
import Link, { type LinkProps } from '@/components_new/Link.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import UserAndBalance from '@/components_new/UserAndBalance.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import { commonNavigationTranslation } from '@/utils/translations/common-navigation'
import { type IconNameType } from '@/components_new/Icon'
import { ROUTE_NAMES } from '@/router/constants'
import { LinkTarget } from '@/types'

type MenuItem = {
    title: string
    link: string
    target?: LinkTarget
    external?: boolean
    icon?: IconNameType
    color?: LinkProps['color']
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LeftSide',
  setup(__props) {

const { t } = useI18n({
    messages: {
        en: {
            ...commonNavigationTranslation.en,
            btn_shop: 'Shop',
            btn_categories: 'Games categories',
        },
    },
})

const restMenuItems: MenuItem[] = [
    {
        title: 'developers',
        link: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank' as const,
        external: true, // Do not remove. This route is not in the Vue app
    },
    // Temporary disable to fit Ad, until better design
    // {
    //     title: 'advertisers',
    //     link: '/advertisers',
    // },
    // {
    //     title: 'publishers',
    //     link: '/publishers',
    // },
    // {
    //     title: 'We are hiring',
    //     icon: 'linked_in' as const,
    //     color: 'golden' as const,
    //     link: 'https://www.linkedin.com/company/playgama',
    //     target: '_blank' as const,
    //     external: true,
    // },
]

const { setCategoriesModalOpened } = useLayoutStore()

function openCategories() {
    setCategoriesModalOpened(true)
}

const userStore = useUserStore()
const route = useRoute()
const isUserProfilePage = computed(() => route.name === ROUTE_NAMES.PROFILE)
const isShop = computed(() => route.name === ROUTE_NAMES.SHOP)
const isMain = computed(() => route.name === ROUTE_NAMES.MAIN)

const { user, isAuthorized, userBalance, isUserInfoLoaded } = storeToRefs(userStore)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.sidebar)
  }, [
    _createVNode(Logo, {
      class: _normalizeClass(_ctx.$style.logo),
      noLink: isMain.value
    }, null, 8, ["class", "noLink"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sections)
    }, [
      (_unref(isUserInfoLoaded))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(isAuthorized) && _unref(user))
              ? (_openBlock(), _createBlock(BaseLink, {
                  key: 0,
                  to: "/profile",
                  disabled: isUserProfilePage.value,
                  class: _normalizeClass(_ctx.$style.section)
                }, {
                  default: _withCtx(() => [
                    _createVNode(UserAndBalance, {
                      user: _unref(user),
                      userBalance: _unref(userBalance),
                      isProfilePage: isUserProfilePage.value
                    }, null, 8, ["user", "userBalance", "isProfilePage"])
                  ]),
                  _: 1
                }, 8, ["disabled", "class"]))
              : _createCommentVNode("", true),
            _createVNode(PromoSignInHorizontalShort)
          ], 64))
        : _createCommentVNode("", true),
      (!isUserProfilePage.value)
        ? (_openBlock(), _createBlock(TasksWidget, {
            key: 1,
            showOnlyFirst: "",
            redirect: !isUserProfilePage.value
          }, {
            default: _withCtx(() => [
              _createVNode(PromoInviteVertical, { title: "50 000 +" })
            ]),
            _: 1
          }, 8, ["redirect"]))
        : _createCommentVNode("", true),
      (isUserProfilePage.value)
        ? (_openBlock(), _createBlock(PromoInviteVertical, {
            key: 2,
            title: "50 000 +"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.section)
      }, [
        _createVNode(Typography, {
          is: "ul",
          class: _normalizeClass(_ctx.$style.links),
          type: "label",
          size: "s",
          accent: true,
          responsive: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: "",
                color: 'white-60',
                class: _normalizeClass(_ctx.$style.links_item),
                onClick: openCategories
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('btn_categories')), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.inner_sep)
              }, null, 2),
              _createVNode(Link, {
                to: "/shop",
                disabled: isShop.value,
                color: 'white-60',
                class: _normalizeClass(_ctx.$style.links_item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('btn_shop')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "class"])
            ]),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(restMenuItems, (item) => {
              return _createElementVNode("li", {
                key: item.title
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.inner_sep)
                }, null, 2),
                _createVNode(Link, {
                  target: item.target,
                  to: item.link,
                  external: item.external,
                  color: item.color || 'white-60',
                  class: _normalizeClass(_ctx.$style.links_item)
                }, {
                  default: _withCtx(() => [
                    (item.icon)
                      ? (_openBlock(), _createBlock(Icon, {
                          key: 0,
                          name: item.icon,
                          class: _normalizeClass(_ctx.$style.links_item_icon)
                        }, null, 8, ["name", "class"]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_unref(t)(item.title)), 1)
                  ]),
                  _: 2
                }, 1032, ["target", "to", "external", "color", "class"])
              ])
            }), 64))
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ], 2),
    _createVNode(AdBlock, {
      type: "sidebar",
      refreshSec: 30,
      class: _normalizeClass(_ctx.$style.ad)
    }, null, 8, ["class"])
  ], 2))
}
}

})