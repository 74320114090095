import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Button from '@/components_new/Button.vue'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'
import FriendImg from '../images/friend.webp'

interface PromoInviteVerticalProps {
    title?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoInviteVertical',
  props: {
    title: { default: '50 000' }
  },
  setup(__props: any) {

const props = __props

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
        },
    },
})

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)

return (_ctx: any,_cache: any) => {
  return (_unref(isAuthorized) && _unref(isUserInfoLoaded))
    ? (_openBlock(), _createBlock(PromoBanner, {
        key: 0,
        description: _unref(t)('invite_desc'),
        direction: "column",
        icon: "fennek_coin_small",
        image: { src: _unref(FriendImg), alt: _unref(t)('invite_alt') },
        title: props.title
      }, {
        default: _withCtx(({ handleClick }) => [
          _createVNode(Button, {
            size: "l",
            onClick: ($event: any) => (handleClick('invite'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('invite_btn')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["description", "image", "title"]))
    : _createCommentVNode("", true)
}
}

})