import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import InviteImg from '@/components_new/ModalItems/images/invite-friend.webp'
import { useI18n } from 'vue-i18n'
import { useLayoutStore } from '@/store/layout-store'
import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import TextInput from '@/components_new/TextInput/TextInput.vue'
import { useConfig } from '@/composables/config'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalInvite',
  setup(__props) {

const { t } = useI18n()
const layoutStore = useLayoutStore()
const userStore = useUserStore()
const router = useRouter()

const { inviteModalOpened } = storeToRefs(layoutStore)

const { VUE_APP_HOST_URL } = useConfig()
const inviteLinkAbsolute = computed(
    () =>
        (VUE_APP_HOST_URL || '') +
        router.resolve({ name: 'invite', params: { inviteCode: userStore.user?.referralCode } }).href,
)
const copyBtnDisabled = ref<boolean>(false)
const copyBtnLabel = computed(() => (copyBtnDisabled.value ? t('btn_label_copied') : t('btn_label')))

function onCloseModal() {
    layoutStore.setInviteModalOpened(false)
}

function onCopyLink() {
    if (!inviteLinkAbsolute.value) {
        return
    }
    navigator.clipboard.writeText(inviteLinkAbsolute.value).then(
        () => {
            copyBtnDisabled.value = true
            setTimeout(() => {
                copyBtnDisabled.value = false
            }, 1000 * 2)
        },
        () => {
            console.error('Failed to copy text')
        },
    )
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalContainer, {
    title: _unref(t)('title'),
    description: _unref(t)('description'),
    image: _unref(InviteImg),
    alt: _unref(t)('alt'),
    isOpened: _unref(inviteModalOpened),
    class: _normalizeClass(_ctx.$style.dialog_invite),
    onCloseModal: onCloseModal
  }, {
    "custom-content": _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.hr)
        }, null, 2),
        _createVNode(Typography, {
          is: "ol",
          class: _normalizeClass(_ctx.$style.content_description),
          type: "paragraph",
          size: "s",
          responsive: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", null, _toDisplayString(_unref(t)('step1')), 1),
            _createElementVNode("li", null, _toDisplayString(_unref(t)('step2')), 1),
            _createElementVNode("li", null, _toDisplayString(_unref(t)('step3')), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.desktop)
        }, [
          (inviteLinkAbsolute.value)
            ? (_openBlock(), _createBlock(TextInput, {
                key: 0,
                id: "invite-link",
                name: "invite-link",
                readonly: "",
                value: inviteLinkAbsolute.value
              }, null, 8, ["value"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.buttons)
        }, [
          _createVNode(Button, {
            width: "full",
            size: "l",
            disabled: copyBtnDisabled.value,
            onClick: onCopyLink
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(copyBtnLabel.value), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(Button, {
            width: "full",
            color: "carbon-400",
            size: "l",
            disabled: copyBtnDisabled.value,
            class: _normalizeClass(_ctx.$style.non_desktop),
            onClick: onCloseModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('close')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "class"])
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["title", "description", "image", "alt", "isOpened", "class"]))
}
}

})