import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue'
import Typography from '@/components_new/Typography.vue'

type UserAvatarProps = {
    avatar?: string
    firstName?: string
    lastName?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAvatar',
  props: {
    avatar: {},
    firstName: {},
    lastName: {}
  },
  setup(__props: any) {

const props = __props

const avatarPlaceholder = computed(
    () => `${props.firstName?.[0]?.toUpperCase() || 'x'}${props.lastName?.[0]?.toUpperCase() || ''}`,
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.avatar)
  }, [
    _createVNode(Typography, {
      class: _normalizeClass(_ctx.$style.placeholder),
      type: "label",
      size: "xs",
      accent: true,
      responsive: false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(avatarPlaceholder.value), 1)
      ]),
      _: 1
    }, 8, ["class"]),
    (props.avatar)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({ backgroundImage: `url(${props.avatar})` }),
          class: _normalizeClass(_ctx.$style.img)
        }, null, 6))
      : _createCommentVNode("", true)
  ], 2))
}
}

})