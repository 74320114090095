import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import SignInImg from '@/components_new/ModalItems/images/sign-in.webp'
import { useI18n } from 'vue-i18n'
import { useLayoutStore } from '@/store/layout-store'
import { storeToRefs } from 'pinia'
import GoogleAuth from '@/components_new/GoogleAuth.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalSignIn',
  setup(__props) {

const { t } = useI18n()
const layoutStore = useLayoutStore()

const { signInModalOpened } = storeToRefs(layoutStore)

function onCloseModal() {
    layoutStore.setSignInModalOpened(false)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalContainer, {
    title: _unref(t)('title'),
    description: _unref(t)('description'),
    image: _unref(SignInImg),
    alt: _unref(t)('alt'),
    isOpened: _unref(signInModalOpened),
    onCloseModal: onCloseModal
  }, {
    actions: _withCtx(() => [
      _createVNode(GoogleAuth, {
        class: _normalizeClass(_ctx.$style.button_sing_in),
        onFinish: onCloseModal
      }, null, 8, ["class"])
    ]),
    _: 1
  }, 8, ["title", "description", "image", "alt", "isOpened"]))
}
}

})