import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { storeToRefs } from 'pinia'
import LeftSide from '@/components_new/LeftSide/LeftSide.vue'
import Footer from '@/components_new/Footer.vue'
import DesktopHeader from '@/components_new/DesktopHeader.vue'
import MobileHeader from '@/components_new/MobileHeader.vue'
import ModalCategories from '@/components_new/ModalCategories/ModalCategories.vue'
import { useLayoutStore } from '@/store/layout-store'
import { watch, hydrateOnInteraction, hydrateOnIdle, hydrateOnVisible } from 'vue'
import ModalInvite from '@/components_new/ModalItems/components/ModalInvite.vue'
import LazyHydration from '@/components_new/LazyHydration.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModernTwoColumnsLayout',
  setup(__props) {

const { fullScreen } = storeToRefs(useLayoutStore())

watch(fullScreen, (value) => {
    document.documentElement.classList.toggle('fullscreen_css', value)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.grid)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.right)
      }, [
        _createVNode(LazyHydration, {
          hydrate: hydrateOnInteraction(['touchstart', 'mousemove', 'focusin'])
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.desktop_header)
            }, [
              _createVNode(DesktopHeader)
            ], 2)
          ]),
          _: 1
        }, 8, ["hydrate"]),
        _createVNode(LazyHydration, {
          hydrate: hydrateOnVisible()
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.mobile_header)
            }, [
              _createVNode(MobileHeader)
            ], 2)
          ]),
          _: 1
        }, 8, ["hydrate"]),
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(LazyHydration, {
          hydrate: hydrateOnIdle()
        }, {
          default: _withCtx(() => [
            _createVNode(Footer)
          ]),
          _: 1
        }, 8, ["hydrate"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.left)
      }, [
        _createVNode(LeftSide)
      ], 2)
    ], 2),
    _createVNode(LazyHydration, {
      hydrate: hydrateOnIdle()
    }, {
      default: _withCtx(() => [
        _createVNode(ModalCategories),
        _createVNode(ModalInvite)
      ]),
      _: 1
    }, 8, ["hydrate"])
  ], 2))
}
}

})