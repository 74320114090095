import { createSSRApp, createApp as createClientSideApp } from 'vue'
import { createHead, VueHeadMixin } from '@unhead/vue'
import getRouter from '@/router'
import FloatingVue from 'floating-vue'
import FloatingThemes from '@/components_new/Floating/floating-themes'
import App from '@/App.vue'
import i18n from '@/i18n'
import createPinia from '@/pinia'
import { createConfigPlugin } from '@/composables/config'
import { setAPIHost } from '@/utils/requests/request'

const run = async () => {
    let appConfig = window.additionalServerData?.config
    if (process.env.NODE_ENV !== 'production') {
        // TODO https://playgama.atlassian.net/browse/DEV-1032
        appConfig = (await import('@/modules/config/createConfig')).createVueAppConfig(process.env)
    }
    if (!appConfig) {
        throw new Error('No app config provided')
    }

    // TODO: refactor utils/requests
    setAPIHost(appConfig.API_HOST)

    const configPlugin = createConfigPlugin(appConfig)
    const head = createHead()
    const router = getRouter(false)
    const pinia = createPinia()
    pinia.state.value = window.__pinia || {}

    const createApp = process.env.NODE_ENV === 'production' ? createSSRApp : createClientSideApp
    const app = createApp(App)
        .use(configPlugin)
        .use(i18n())
        .use(router)
        .use(pinia)
        .use(head)
        .use(FloatingVue, FloatingThemes)
        .mixin(VueHeadMixin)

    await router.isReady()

    app.mount('#app')
}

run()
