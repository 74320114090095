import { FunctionPlugin, inject } from 'vue'
import type { VUE_APP_CONFIG } from '@/modules/config/createConfig'

const CONFIG_PROVIDE_KEY = Symbol('config')

export const createConfigPlugin =
    (config: VUE_APP_CONFIG): FunctionPlugin =>
    (app) => {
        app.provide(CONFIG_PROVIDE_KEY, config)
    }

export function useConfig(): VUE_APP_CONFIG {
    const config = inject<VUE_APP_CONFIG>(CONFIG_PROVIDE_KEY)
    if (!config) {
        throw new Error('No config provided in Vue app.')
    }
    return config
}
