import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, onServerPrefetch } from 'vue'
import { storeToRefs } from 'pinia'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { useCategoriesStore } from '@/store/categories-store'
import ErrorContent from '@/components_new/ErrorContent/ErrorContent.vue'
import { type ErrorStatus } from '@/store/app-store'


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorPage',
  props: {
    type: {}
  },
  setup(__props: any) {

const categoriesStore = useCategoriesStore()
const { randomGameHRU } = storeToRefs(categoriesStore)



onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    await categoriesStore.setMainData()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(ErrorContent, {
        type: _ctx.type,
        randomGameHRU: _unref(randomGameHRU),
        showButtons: ""
      }, null, 8, ["type", "randomGameHRU"])
    ]),
    _: 1
  }))
}
}

})