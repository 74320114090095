import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, renderSlot as _renderSlot } from "vue"

import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import Icon from '@/components_new/Icon/Icon.vue'
import TaskWidget from '@/components_new/TaskItems/TaskWidget.vue'
import Button from '@/components_new/Button.vue'
import { tasksTranslation } from '@/components_new/TaskItems/utils'
import { useTasksStore } from '@/components_new/TaskItems/store'
import BaseLink from '@/components_new/BaseLink.vue'
import { useUserStore } from '@/store/user-store/user-store'

interface TasksWidgetProps {
    showOnlyFirst?: boolean
    redirect?: boolean
    appearance?: 'default' | 'accent'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TasksWidget',
  props: {
    showOnlyFirst: { type: Boolean },
    redirect: { type: Boolean },
    appearance: { default: 'default' }
  },
  setup(__props: any) {

const props = __props

const tasksStore = useTasksStore()
const userStore = useUserStore()
const { tasksError, showTasks, allTasksClaimed, notClaimedTasks } = storeToRefs(tasksStore)

const { isAuthorized } = storeToRefs(userStore)
const { t } = useI18n({
    messages: {
        en: {
            ...tasksTranslation.en,
            title: 'Daily Missions',
        },
    },
})

const tasksToShow = computed(() => {
    if (allTasksClaimed.value) {
        return []
    }
    return props.showOnlyFirst ? notClaimedTasks.value.slice(0, 1) : notClaimedTasks.value
})

return (_ctx: any,_cache: any) => {
  return (_unref(isAuthorized) && (_unref(showTasks) || _ctx.$slots.default))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.task_widgets)
      }, [
        (_unref(tasksError))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(props.redirect ? BaseLink : 'div'), {
                to: "/profile",
                class: _normalizeClass(_ctx.$style.title)
              }, {
                default: _withCtx(() => [
                  _createVNode(Typography, { type: "caption_all-caps" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('title')), 1)
                    ]),
                    _: 1
                  }),
                  (props.redirect)
                    ? (_openBlock(), _createBlock(Icon, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style.chevron),
                        name: "chevron_r"
                      }, null, 8, ["class"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["class"])),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.inner_sep)
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.error_container)
              }, [
                _createVNode(Typography, {
                  type: "label",
                  size: "l"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('error_title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(Button, {
                  color: "carbon-400",
                  visual: "fill",
                  onClick: _unref(tasksStore).onReloadClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('error_button')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ], 2)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_unref(allTasksClaimed))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(props.redirect ? BaseLink : 'div'), {
                      to: "/profile",
                      class: _normalizeClass(_ctx.$style.title)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Typography, { type: "caption_all-caps" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('title')), 1)
                          ]),
                          _: 1
                        }),
                        (props.redirect)
                          ? (_openBlock(), _createBlock(Icon, {
                              key: 0,
                              class: _normalizeClass(_ctx.$style.chevron),
                              name: "chevron_r"
                            }, null, 8, ["class"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["class"])),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tasksToShow.value, (task) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: task.task_id
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.$style.inner_sep)
                        }, null, 2),
                        _createVNode(TaskWidget, {
                          task: task,
                          appearance: props.appearance,
                          onClaimTask: _unref(tasksStore).onTaskClaimed,
                          onTaskStart: _unref(tasksStore).onTaskStart
                        }, null, 8, ["task", "appearance", "onClaimTask", "onTaskStart"])
                      ], 64))
                    }), 128))
                  ], 64))
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ], 64))
      ], 2))
    : _createCommentVNode("", true)
}
}

})