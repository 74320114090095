import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineAsyncComponent, defineComponent, type HydrationStrategy } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LazyHydration',
  props: {
    hydrate: { type: Function }
  },
  setup(__props: any) {

const props = __props

const SlotComponent = defineComponent({
    render() {
        return this.$slots.default?.()
    },
})

const LazySlot = defineAsyncComponent({
    loader: () => Promise.resolve(SlotComponent),
    hydrate: props.hydrate,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(LazySlot), null, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})