<template>
    <LazySlot>
        <slot />
    </LazySlot>
</template>
<script setup lang="ts">
import { defineAsyncComponent, defineComponent, type HydrationStrategy } from 'vue'

const props = defineProps<{
    hydrate: HydrationStrategy
}>()

const SlotComponent = defineComponent({
    render() {
        return this.$slots.default?.()
    },
})

const LazySlot = defineAsyncComponent({
    loader: () => Promise.resolve(SlotComponent),
    hydrate: props.hydrate,
})
</script>
