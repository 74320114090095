import { ref } from 'vue'
import { defineStore } from 'pinia'
import { MainCategoriesType, ShortGameType, CategoryShortInfo, CategoryType } from '@/types'

export type ShortCategory = {
    id: MainCategoriesType
    title: string
    href: string
}
import { getGamesCategoriesShortInfo, getMainPageGamesByCategories } from '@/utils/requests/games'
import { shuffle } from '@/utils/helpers'
import { useAppStore } from '@/store/app-store'
import { useUrl } from '@/composables/url-helpers'

const MAX_PROMO_CATEGORIES = 7

export const HIGHLIGHTED_CATEGORY_TYPES: MainCategoriesType[] = ['trending_now', 'new']
const RANDOM_CATEGORY = 'random'
export const TOPIC_CATEGORY_TYPES: MainCategoriesType[] = [
    'io',
    'action',
    'puzzle',
    'arcade',
    'sports',
    'strategy',
    'bubble-shooter',
    'girls',
    'boys',
    'car',
    'simulation',
    'shooting',
    'gun',
    'clicker',
    'funny',
    'snake',
    'skill',
    'drawing',
    'idle',
    'multiplayer',
    'solitaire',
    'horror',
    'two-player',
]

export const useCategoriesStore = defineStore('categories', () => {
    const menuHighlightCategoriesList = ref<ShortCategory[]>([])
    const contentCategoriesList = ref<ShortCategory[]>([])
    const menuCategoriesList = ref<ShortCategory[]>([])
    const promoCategoriesList = ref<ShortCategory[]>([])
    const randomGames = ref<string[]>([])
    const recommendedGames = ref<ShortGameType[] | null>(null)
    const randomGameHRU = ref<string | null>(null)
    const categoriesInfo = ref<Record<CategoryType, CategoryShortInfo[]>>(
        {} as Record<CategoryType, CategoryShortInfo[]>,
    )

    const appStore = useAppStore()
    const { getCategoryRoute } = useUrl()

    async function setMainCategories(): Promise<void> {
        const currentPlatform = appStore.platform
        const data = await getMainPageGamesByCategories({
            platform: currentPlatform,
        })
        const notInternal: ShortCategory[] = []
        const internalCategories: ShortCategory[] = []
        const { categories } = data
        categories.forEach(({ id, title, games }) => {
            if (!games.length) {
                return
            }
            const mainCategoryId = id as MainCategoriesType
            if (HIGHLIGHTED_CATEGORY_TYPES.includes(mainCategoryId)) {
                internalCategories.push({
                    id: mainCategoryId,
                    title,
                    href: getCategoryRoute(id),
                })
            } else if (mainCategoryId === RANDOM_CATEGORY) {
                randomGames.value = games.map((game) => game.hru)
            } else {
                notInternal.push({
                    id: mainCategoryId,
                    title,
                    href: getCategoryRoute(id),
                })
            }
        })

        contentCategoriesList.value = [...internalCategories, ...notInternal]
        menuCategoriesList.value = notInternal
        promoCategoriesList.value = shuffle(notInternal.slice()).slice(
            0,
            Math.min(MAX_PROMO_CATEGORIES, notInternal.length),
        )
        menuHighlightCategoriesList.value = internalCategories
    }
    async function setCategoriesInfo(type: CategoryType): Promise<void> {
        if (categoriesInfo.value[type] !== undefined) {
            return
        }
        const result = await getGamesCategoriesShortInfo(type)
        categoriesInfo.value[type] = result.map((catInfo) => ({ ...catInfo, href: getCategoryRoute(catInfo.id, type) }))
    }

    function updateRandomGame() {
        const currentIndex = randomGames.value.findIndex((hru) => hru === randomGameHRU.value) || 0
        const nextIndex = currentIndex + 1
        randomGameHRU.value = randomGames.value[nextIndex % randomGames.value.length]
    }

    function shuffleRandomGames(): void {
        randomGames.value = shuffle(randomGames.value)
        updateRandomGame()
    }
    // For Easy shuffling on UI side - as we have cache on ssr we have to reshuffle after page rendered
    async function setMainData() {
        if (contentCategoriesList.value.length) {
            if (typeof window !== 'undefined') {
                shuffleRandomGames()
            }
        } else {
            await setMainCategories()
            if (typeof window !== 'undefined' && contentCategoriesList.value.length) {
                shuffleRandomGames()
            }
        }
    }

    return {
        menuHighlightCategoriesList,
        menuCategoriesList,
        promoCategoriesList,
        contentCategoriesList,
        categoriesInfo,
        recommendedGames,
        setMainData,
        setCategoriesInfo,
        getCategoryRoute,
        // expose to capture on server and hydrate on client
        randomGames,
        randomGameHRU,
        updateRandomGame,
    }
})
