import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Button from '@/components_new/Button.vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import Typography from '@/components_new/Typography.vue'
import Balance from '@/components_new/Balance.vue'
import { computed, onMounted, ref, useCssModule } from 'vue'
import { useDebounceFn, useResizeObserver } from '@vueuse/core'
import BaseLink from '@/components_new/BaseLink.vue'
import { ROUTE_NAMES } from '@/router/constants'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'

// to control state from the parent component and let two headers co-exist in differen states

export default /*@__PURE__*/_defineComponent({
  __name: 'MobileHeader',
  props: {
    categoriesModalMode: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const logoWrapRef = ref<HTMLElement | null>(null)
const isClientSide = ref(false)

const { t } = useI18n()

const userStore = useUserStore()
const styles = useCssModule()

const route = useRoute()
const router = useRouter()
const isMain = computed(() => route.name === ROUTE_NAMES.MAIN)

function logout() {
    userStore.logout()
    router.replace('/shop')
}

const { user, /* taskStats, */ isAuthorized, userBalance, isUserInfoLoaded } = storeToRefs(userStore)

const layoutStore = useLayoutStore()

const { categoriesModalOpened } = storeToRefs(layoutStore)
function menuToggle() {
    layoutStore.setCategoriesModalOpened(!categoriesModalOpened.value)
}

function signin() {
    layoutStore.setSignInModalOpened(true)
}

function onResize() {
    const logoWrapRefWidth = logoWrapRef.value?.clientWidth
    const logo = logoWrapRef.value?.querySelector<HTMLElement>(`.${styles.logo}`)
    const logoShort = logoWrapRef.value?.querySelector<HTMLElement>(`.${styles.logo_short}`)
    if (!logo || !logoShort || !logoWrapRefWidth) {
        return
    }
    logo.hidden = false
    logoShort.hidden = true
    if (logoWrapRefWidth <= logo.clientWidth) {
        logo.hidden = true
        logoShort.hidden = false
    }
}

const debouncedOnResize = useDebounceFn(onResize, 50)
useResizeObserver(logoWrapRef, debouncedOnResize)

const isMounted = useIsMounted()
onMounted(() => {
    isClientSide.value = true
    onResize()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.header)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.leftBlock)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.menu)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.short_menu)
        }, [
          (props.categoriesModalMode)
            ? (_openBlock(), _createBlock(Button, {
                key: 0,
                iconLeft: "close",
                visual: "ghost",
                color: "purple-500",
                onClick: menuToggle
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('close')), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("button", {
                  style: {"display":"flex","padding":"0"},
                  onClick: menuToggle
                }, [
                  _createVNode(Icon, {
                    name: "menu",
                    size: "m"
                  })
                ]),
                _createElementVNode("div", {
                  ref_key: "logoWrapRef",
                  ref: logoWrapRef,
                  class: _normalizeClass(_ctx.$style.logo_wrapper)
                }, [
                  _createVNode(Logo, {
                    class: _normalizeClass(_ctx.$style.logo),
                    noLink: isMain.value
                  }, null, 8, ["class", "noLink"]),
                  (isClientSide.value)
                    ? (_openBlock(), _createBlock(Logo, {
                        key: 0,
                        noLink: isMain.value,
                        short: "",
                        class: _normalizeClass(_ctx.$style.logo_short),
                        hidden: ""
                      }, null, 8, ["noLink", "class"]))
                    : _createCommentVNode("", true)
                ], 2)
              ], 64))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.wide_menu)
        }, [
          _createVNode(Logo, {
            class: _normalizeClass(_ctx.$style.logo),
            noLink: isMain.value
          }, null, 8, ["class", "noLink"]),
          _createVNode(Button, {
            iconLeft: "menu",
            size: "l",
            visual: "ghost",
            color: "purple-500",
            onClick: menuToggle
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('all_games')), 1)
            ]),
            _: 1
          })
        ], 2)
      ], 2)
    ], 2),
    (_unref(isUserInfoLoaded) && _unref(isMounted))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "rightBlockRef",
          class: _normalizeClass(_ctx.$style.rightBlock)
        }, [
          (_unref(isAuthorized) && _unref(user))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_unref(route).name === _unref(ROUTE_NAMES).PROFILE)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(UserAvatar, {
                        class: _normalizeClass(_ctx.$style.avatar),
                        avatar: _unref(user).avatar,
                        firstName: _unref(user).firstName,
                        lastName: _unref(user).lastName
                      }, null, 8, ["class", "avatar", "firstName", "lastName"]),
                      _createVNode(Button, {
                        color: "carbon-400",
                        size: "m",
                        icon: "logout",
                        onClick: logout
                      })
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(BaseLink, { to: "/profile" }, {
                        default: _withCtx(() => [
                          _createVNode(UserAvatar, {
                            class: _normalizeClass(_ctx.$style.avatar),
                            avatar: _unref(user).avatar,
                            firstName: _unref(user).firstName,
                            lastName: _unref(user).lastName
                          }, null, 8, ["class", "avatar", "firstName", "lastName"])
                        ]),
                        _: 1
                      }),
                      _createVNode(BaseLink, {
                        to: "/profile",
                        class: _normalizeClass(_ctx.$style.coins)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(Icon, {
                            name: "fennek_coin_small",
                            class: _normalizeClass(_ctx.$style.coins_icon)
                          }, null, 8, ["class"]),
                          _createVNode(Typography, {
                            type: "header",
                            size: "xs",
                            responsive: false,
                            class: _normalizeClass(_ctx.$style.fennecs)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(Balance, { balance: _unref(userBalance) }, null, 8, ["balance"])
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ], 64))
              ], 64))
            : (_openBlock(), _createBlock(Button, {
                key: 1,
                size: "m",
                onClick: signin
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('login')), 1)
                ]),
                _: 1
              }))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})