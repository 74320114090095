import { RouteLocationRaw, useRouter } from 'vue-router'
import { useAppStore } from '@/store/app-store'
import { ROUTE_NAMES } from '@/router/constants'
import { CategoryType } from '@/types'

export function useUrl() {
    const URL_PARAMS_TO_KEEP = ['clid', 'autoplay']
    const { queryParams } = useAppStore()
    const router = useRouter()
    function keepRequiredParams(url: string | RouteLocationRaw): string | RouteLocationRaw {
        try {
            const paramsToAdd = new URLSearchParams()

            queryParams.forEach(([key, value]) => {
                if (URL_PARAMS_TO_KEEP.includes(key)) {
                    paramsToAdd.set(key, value as string)
                }
            })

            if (typeof url === 'string') {
                return `${url}${paramsToAdd.size ? `?${paramsToAdd.toString()}` : ''}`
            } else {
                const resultQuery = url.query ? { ...url.query } : {}

                paramsToAdd.forEach((value, key) => {
                    resultQuery[key] = value
                })

                return {
                    ...url,
                    query: resultQuery,
                }
            }
        } catch {
            throw Error('Could not retrieve URL params')
        }
    }

    function getCategoryRoute(id: string, type: CategoryType = 'category'): string {
        try {
            const route = router.resolve({ name: ROUTE_NAMES.CATEGORY, params: { id, type } })
            return route.path
        } catch {
            throw Error(`Could not resolve category - ${id}`)
        }
    }

    return { keepRequiredParams, getCategoryRoute }
}
