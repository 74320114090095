<template>
    <a
        v-if="props.external"
        :href="url as string"
        :target="props.target"
        :class="[$style.baseLink, props.disabled ? $style.disabled : '']"
        @click="emit('click', $event)"
    >
        <slot />
    </a>
    <RouterLink
        v-else
        :to="url"
        :target="props.target"
        :class="[$style.baseLink, props.disabled ? $style.disabled : '']"
        @click="emit('click', $event)"
    >
        <slot />
    </RouterLink>
</template>

<script lang="ts" setup>
import { RouteLocationRaw, RouterLink } from 'vue-router'
import { computed } from 'vue'
import { LinkTarget } from '@/types'
import { useUrl } from '@/composables/url-helpers'

export interface BaseLinkProps {
    disabled?: boolean
    to: string | RouteLocationRaw
    target?: LinkTarget
    external?: boolean
}

const props = defineProps<BaseLinkProps>()
const { keepRequiredParams } = useUrl()

const url = computed(() => {
    if (props.external) {
        if (typeof props.to !== 'string') {
            throw new Error('External link must have a string URL')
        }
        return props.to
    }
    return keepRequiredParams(props.to)
})

const emit = defineEmits<{ click: [value: Event] }>()
</script>

<style module>
.baseLink.disabled,
.baseLink.disabled:hover,
.baseLink.disabled:focus-within {
    pointer-events: none;
    cursor: default;
}
</style>
