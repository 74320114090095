import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, ref, watch, useCssModule } from 'vue'
import { useResizeObserver, useDebounceFn, useTransition, TransitionPresets } from '@vueuse/core'
import Tooltip from '@/components_new/Tooltip.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Balance',
  props: {
    balance: {}
  },
  setup(__props: any) {

const props = __props

const styles = useCssModule()

function formatFullValue(value: number) {
    if (value < 10_000) {
        return value.toString()
    }
    return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
}
function getValues({ maxChars, value }: { maxChars: number; value: number }): {
    display: string
    tooltip: string | undefined
} {
    const displayFullValue = formatFullValue(value)
    const displayFullValueChars = displayFullValue.length

    if (value < 1000) {
        return { display: displayFullValue, tooltip: undefined }
    }

    if (value < 1_000_000) {
        if (maxChars < displayFullValueChars) {
            return { display: `${Math.floor(value / 1000)}k`, tooltip: displayFullValue }
        }
        return { display: displayFullValue, tooltip: undefined }
    }

    if (maxChars < displayFullValueChars) {
        return { display: `${Math.floor(value / 1_000_000)}M`, tooltip: displayFullValue }
    }
    return { display: displayFullValue, tooltip: undefined }
}

const tooltipContent = ref<string | undefined>(undefined)
const containerRef = ref<HTMLElement | null>(null)
const spanRef = ref<HTMLElement | null>(null)
const maxChars = ref<number | undefined>(undefined)
const computedBalance = computed(() => props.balance) // to enable reactivity on useTransition
const animatedBalance = useTransition(computedBalance, {
    duration: 1000,
    transition: TransitionPresets.linear,
    onStarted: () => {
        spanRef.value?.classList.add(styles.bouncing)
    },
    onFinished: () => {
        spanRef.value?.classList.remove(styles.bouncing)
    },
})

function renderValues() {
    if (maxChars.value === undefined || !spanRef.value) {
        return
    }

    const { display, tooltip } = getValues({ maxChars: maxChars.value, value: Math.round(animatedBalance.value) })
    tooltipContent.value = tooltip
    spanRef.value.textContent = display
}

watch(animatedBalance, renderValues)

function onResize() {
    const container = containerRef.value
    const span = spanRef.value
    if (!container || !span) {
        return
    }

    span.textContent = ''
    const availableWidth = container.clientWidth
    span.textContent = 'X' // 1 wide character to measure
    const charWidth = span.clientWidth

    maxChars.value = Math.floor(availableWidth / charWidth)

    renderValues()
}

const debouncedOnResize = useDebounceFn(onResize, 100)
useResizeObserver(containerRef, debouncedOnResize)

onMounted(() => {
    onResize()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "containerRef",
    ref: containerRef,
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(Tooltip, {
      content: tooltipContent.value,
      class: _normalizeClass(_ctx.$style.tooltip)
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          ref_key: "spanRef",
          ref: spanRef,
          class: _normalizeClass(_ctx.$style.text)
        }, null, 2)
      ]),
      _: 1
    }, 8, ["content", "class"])
  ], 2))
}
}

})