import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

interface TypographyProps {
    type?: 'paragraph' | 'header' | 'label' | 'caption' | 'caption_all-caps'
    size?: 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'
    accent?: boolean
    responsive?: boolean
    is?: keyof HTMLElementTagNameMap
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Typography',
  props: {
    type: {},
    size: {},
    accent: { type: Boolean },
    responsive: { type: Boolean, default: true },
    is: { default: 'span' }
  },
  setup(__props: any) {

const props = __props

const componentTypeMap: Record<string, string> = {
    h1: 'header',
    h2: 'header',
    h3: 'header',
    h4: 'header',
    h5: 'header',
    h6: 'header',
    h7: 'header',
    label: 'caption',
    p: 'paragraph',
    span: 'paragraph',
}

const className = computed(() => {
    let type = ''
    if (props.type) {
        type = props.type
    } else if (props.is) {
        const isKey = props.is
        type = componentTypeMap[isKey] ?? ''
    }
    return props.size ? `${type}_${props.size}` : type
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(props.is), {
    class: _normalizeClass([
            _ctx.$style.typography,
            { [_ctx.$style.accent]: _ctx.accent },
            { [_ctx.$style.responsive]: _ctx.responsive },
            _ctx.$style[className.value],
        ])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})