import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, onBeforeMount, onMounted, onServerPrefetch, onUnmounted, ref, useSSRContext, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { type RouteLocationNormalized, useRoute, useRouter } from 'vue-router'
import { useAppStore } from '@/store/app-store'
import { useAdsStore } from '@/store/ads-store'
import { useLoggerStore } from '@/store/logger-store'
import { getCurrentPlatform } from '@/utils/helpers'
import { GameProviders, type PlatformType } from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import ErrorPage from '@/pages/Error/ErrorPage.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import { getUACFromCookies } from '@/utils/cookie-parser'
import ModalSignIn from '@/components_new/ModalItems/components/ModalSignIn.vue'
import HeadMeta from '@/components_new/HeadMeta/HeadMeta.vue'
import {
    ROUTE_NAMES,
    ROUTES_WITH_GAME_PARAM,
    ROUTES_WITHOUT_PORTAL_FULSCREEN_AD,
    ROUTES_WITH_PRELOAD_GAME_AD,
    ROUTES_ACCEPTING_NO_ADS_PARAM,
    ROUTES_HIDE_PORTAL_STICKY,
} from '@/router/constants'
import { useConfig } from '@/composables/config'

const GAME_DISTRIBUTION_GAME_ID_KEY = 'gd_game_id'
const GAME_DISTRIBUTION_KEY = 'use_gd_ads'
const DISABLED_ADS = 'use_no_ads'

export default /*@__PURE__*/_defineComponent({
  __name: 'InitWrapper',
  setup(__props) {

// необходимый для SSR и правильных статусов ответов враппер
const appStore = useAppStore()
const adsStore = useAdsStore()
const layoutStore = useLayoutStore()
const loggerStore = useLoggerStore()
const $router = useRouter()
const $route = useRoute()

const { pageStatus, platform: platformFromStore } = storeToRefs(appStore)

const userStore = useUserStore()
const { VUE_APP_USER_TASKS_POLLING_MS, VUE_APP_ADMANAGER_ACCOUNT, VUE_APP_AD_REQUEST_TIMEOUT_SEC } = useConfig()
const taskUpdateTimeOutMs = Number(VUE_APP_USER_TASKS_POLLING_MS)

const manuallyHandledError = computed(() => $route.meta.manuallyHandledErrors)

const isGameDistribution = !!$route.query[GAME_DISTRIBUTION_KEY]

function getPageInfo(page: RouteLocationNormalized) {
    return `${page.name?.toString()} ${page.params.game || page.params.category || ''}`
}

const showSticky = computed(() => {
    return (
        !layoutStore.fullScreen &&
        platformFromStore.value !== 'desktop' &&
        !ROUTES_HIDE_PORTAL_STICKY.includes($route.name as string)
    )
})

onServerPrefetch(() => {
    const ctx = useSSRContext()
    if (ctx?.pageStatus) {
        appStore.setPageStatus(ctx.pageStatus)
    } else {
        appStore.$reset()
        appStore.setPlatform(ctx?.platform as PlatformType)
    }
    appStore.setUrlDetails({ host: ctx?.host, query: $route.query })
})

// Doing all app initialization here, no risk for SSR hydration mismatch
// eslint-disable-next-line no-restricted-syntax
onBeforeMount(() => {
    // кажется тут пора навести порядок. пока хороших идей нет,
    // но пусть этот комментарий на них натолкнет когда-нибудь
    let currentPageSessionStartedAt = Date.now()
    const currentSessionStartedAt = Date.now()
    let currentPageData = getPageInfo($route)

    $router.beforeEach((to) => {
        appStore.setPageStatus(200)

        const delta = Date.now() - currentPageSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: ($route.name as string) || undefined,
        })

        currentPageSessionStartedAt = Date.now()
        currentPageData = getPageInfo(to)
        // reset content_category and provider for upcoming page to avoid misleading targeting data
        const newAdTargeting = {
            content_category: '',
            provider: '',
            hru: '',
            page: $route.name as string,
        }
        if (to.name && ROUTES_WITH_GAME_PARAM.includes(to.name as string)) {
            newAdTargeting.hru = to.params.game as string
        }
        adsStore.updateTargeting(newAdTargeting)
    })

    window.addEventListener('beforeunload', () => {
        const delta = Date.now() - currentPageSessionStartedAt
        const sessionTime = Date.now() - currentSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: ($route.name as string) || undefined,
        })

        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'session_end',
            label: sessionTime / 1000,
            action: currentPageData,
            pageName: ($route.name as string) || undefined,
        })
    })

    const ua = window.navigator.userAgent
    const platform = getCurrentPlatform(ua)
    appStore.setPlatform(platform)

    const adsDisabled = !!$route.query[DISABLED_ADS] && ROUTES_ACCEPTING_NO_ADS_PARAM.includes($route.name as string)
    const userAcquisitionParams = getUACFromCookies(document.cookie)

    if (!appStore.host) {
        appStore.setUrlDetails({ host: window.location.host, query: $route.query })
    }

    const GAME_DISTRIBUTION_GAME_ID = $route.query[GAME_DISTRIBUTION_GAME_ID_KEY]
    if (GAME_DISTRIBUTION_GAME_ID) {
        // TODO pass directly to modules/gd-connector.ts
        window.gd_id = GAME_DISTRIBUTION_GAME_ID as string
    }

    const gamePageTargeting: Record<string, string> = {
        hru: '',
    }
    if ($route.name && ROUTES_WITH_GAME_PARAM.includes($route.name as string)) {
        gamePageTargeting.hru = $route.params.game as string
    }

    let configKey = GameProviders.GOOGLE_AD
    if (isGameDistribution) {
        configKey = GameProviders.GAME_DISTRIBUTION
    }
    if (adsDisabled) {
        configKey = GameProviders.EMPTY_PROVIDER
    }

    const adRequestTimeoutMs = Number(VUE_APP_AD_REQUEST_TIMEOUT_SEC) * 1000
    adsStore.setAdServiceConfig({
        configKey,
        targeting: {
            playgama_clid: appStore.clid,
            widget_id: appStore.widgetId || '',
            ...userAcquisitionParams,
            ...gamePageTargeting,
            page: $route.name as string,
        },
        logEvent: loggerStore.logEvent,
        route: $route,
        preloadGameAd: ROUTES_WITH_PRELOAD_GAME_AD.includes($route.name as string),
        adRequestTimeoutMs,
        gamAccount: VUE_APP_ADMANAGER_ACCOUNT,
    })

    if (
        Object.keys(userAcquisitionParams).length > 0 && // paid user
        adsStore.adMediator && // service is available
        !ROUTES_WITHOUT_PORTAL_FULSCREEN_AD.includes($route.name as string)
    ) {
        // show fullscreen ads for paid users at not game pages
        adsStore.adMediator.prepareAd({ type: 'fullscreen' })
    }

    const conversionValue = window.additionalServerData?.conversionValue

    if (conversionValue !== undefined) {
        window.dataLayer.push({
            conversion_value: conversionValue,
        })
    }
})

const tasksPollingTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null)
function pollTasks() {
    if (!document.hidden) {
        userStore.getUserTasks()
    }
    tasksPollingTimeoutId.value = setTimeout(pollTasks, taskUpdateTimeOutMs)
}

const { user } = storeToRefs(userStore)
watch(
    () => user.value,
    () => {
        if (user.value) {
            window.dataLayer.push({
                user_id: user.value._id,
            })

            // TODO: stop polling tasks on logoff
            pollTasks()
        }
    },
)

onMounted(async () => {
    if ($route.name === ROUTE_NAMES.EXPORT_GAME_PAGE) {
        loggerStore.startExportKeyEventsTracking()
    } else {
        loggerStore.startPortalKeyEventsTracking()
        const userData = await userStore.getUser()
        loggerStore.logEvent({
            event: 'custom_event',
            eventName: 'session_start',
            pageName: $route.name as string,
            label: '',
            action: '',
        })
        if (userData) {
            window.dataLayer.push({
                user_id: userData._id,
            })
        }
    }

    // this enables css :active pseudo-class on touch devices
    const noop = () => {}
    document.body.addEventListener('touchstart', noop, false)
})

onUnmounted(() => {
    if (tasksPollingTimeoutId.value) {
        clearTimeout(tasksPollingTimeoutId.value)
    }
})

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeadMeta, {
      loadGoogleAssets: !isGameDistribution
    }, null, 8, ["loadGoogleAssets"]),
    (_unref(pageStatus) === 200 || manuallyHandledError.value)
      ? (_openBlock(), _createBlock(_component_RouterView, { key: 0 }))
      : (_unref(pageStatus) === 404)
        ? (_openBlock(), _createBlock(ErrorPage, {
            key: 1,
            type: 404
          }))
        : (_unref(pageStatus) >= 500)
          ? (_openBlock(), _createBlock(ErrorPage, {
              key: 2,
              type: 500
            }))
          : _createCommentVNode("", true),
    _createVNode(ModalSignIn),
    (showSticky.value)
      ? (_openBlock(), _createBlock(AdBlock, {
          key: _unref($route).path,
          type: "sticky_portrait"
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})