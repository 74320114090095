import { defineStore } from 'pinia'
import type {
    EventForLoggersType,
    CustomEventForGa,
    CustomPGEvents,
    PlaygamaLoggerEventType,
    AvailablePGEvents,
    AvailableGAEvents,
} from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { useAppStore } from '@/store/app-store'
import { CUSTOM_PG_EVENTS } from '@/constants/general'
import { timer } from '@/utils/helpers'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'
import { KEY_CONVERSION_AD_SHOW_TYPES, OutOfPageAdType } from '@/modules/adv'
import { useConfig } from '@/composables/config'

async function sendEvent(host: string, event: PlaygamaLoggerEventType) {
    const ANALYTICS_PATH = `${host}/api/v1/events`
    try {
        await fetch(ANALYTICS_PATH, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(event),
            credentials: 'include',
            priority: 'low',
        })
    } catch {
        // 🤷‍♂️
    }
}

export type EventData = {
    gameHru?: string
    clid?: string
    userId?: string
    widgetId?: string
}

const PORTAL_KEY_EVENTS: { timeout: number; eventName: AvailablePGEvents }[] = [
    { timeout: 5 * 60 * 1000, eventName: 'timespent_5min' },
    { timeout: 10 * 60 * 1000, eventName: 'timespent_10min' },
    { timeout: 15 * 60 * 1000, eventName: 'timespent_15min' },
]
const EXPORT_KEY_EVENTS: { timeout: number; eventName: AvailablePGEvents }[] = [
    { timeout: 1.5 * 60 * 1000, eventName: 'export_timespent_1.5min' },
    { timeout: 3 * 60 * 1000, eventName: 'export_timespent_3min' },
]

const checkIsGAEvent = (eventName: AvailablePGEvents): eventName is AvailableGAEvents =>
    !CUSTOM_PG_EVENTS.includes(eventName as CustomPGEvents)

const INITIAL_DELAY = 1000 // ms
let initialDelayPromise: null | Promise<void> = null
function intialDelay() {
    if (!initialDelayPromise) {
        initialDelayPromise = timer(INITIAL_DELAY)
    }
    return initialDelayPromise
}

export const useLoggerStore = defineStore('logger', () => {
    const { ANALYTICS_API_HOST } = useConfig()
    async function getEventData(): Promise<EventData> {
        const userStore = useUserStore()
        const appStore = useAppStore()
        const user = await userStore.getUser()
        return {
            clid: appStore.clid,
            userId: user?._id,
            widgetId: appStore.widgetId,
        }
    }

    const route = useRoute()
    const adShowCounter = ref(0)
    const threeMinutesSpentOnExport = ref(false)
    const oneAndHalfMinutesSpentOnExport = ref(false)
    const threeMinutesAndThreeAdShow = ref(false)
    const oneAndHalfMinutesAndTwoAdShow = ref(false)
    function trackCompositeEvents(event: EventForLoggersType) {
        if (route.name === ROUTE_NAMES.EXPORT_GAME_PAGE) {
            if (
                event.eventName === 'ad_show' &&
                KEY_CONVERSION_AD_SHOW_TYPES.includes(event.label as OutOfPageAdType)
            ) {
                adShowCounter.value++
            }
            if (event.eventName === 'export_timespent_3min') {
                threeMinutesSpentOnExport.value = true
            }
            if (event.eventName === 'export_timespent_1.5min') {
                oneAndHalfMinutesSpentOnExport.value = true
            }
            if (adShowCounter.value >= 3 && threeMinutesSpentOnExport.value && !threeMinutesAndThreeAdShow.value) {
                threeMinutesAndThreeAdShow.value = true
                logEvent({
                    event: 'custom_event',
                    eventName: '3min+3ad_show',
                    label: '',
                    action: '',
                })
            }
            if (adShowCounter.value >= 2 && threeMinutesSpentOnExport.value && !oneAndHalfMinutesAndTwoAdShow.value) {
                oneAndHalfMinutesAndTwoAdShow.value = true
                logEvent({
                    event: 'custom_event',
                    eventName: '1.5min+2ad_show',
                    label: '',
                    action: '',
                })
            }
        }
    }

    async function logEvent(event: EventForLoggersType): Promise<void> {
        await intialDelay()
        const { clid, userId, widgetId } = await getEventData()
        const { eventName } = event

        if (checkIsGAEvent(eventName)) {
            const gaEvent: CustomEventForGa = {
                event: event.event,
                eventName,
                label: event.label,
                action: event.action,
                clid,
                widget_id: widgetId || '',
                game_provider: event.gameProvider || '',
            }
            window.dataLayer.push(gaEvent)
        }

        const analyticsEvent: PlaygamaLoggerEventType = {
            eventName: event.eventName,
            pageName: event.pageName,
            gameHru: event.gameHru,
            userId,
            clid,
        }
        sendEvent(ANALYTICS_API_HOST, analyticsEvent)

        trackCompositeEvents(event)
    }

    function startPortalKeyEventsTracking() {
        PORTAL_KEY_EVENTS.forEach(({ timeout, eventName }) => {
            setTimeout(() => {
                logEvent({
                    event: 'custom_event',
                    eventName,
                    label: '',
                    action: '',
                })
            }, timeout)
        })
    }

    function startExportKeyEventsTracking() {
        EXPORT_KEY_EVENTS.forEach(({ timeout, eventName }) => {
            setTimeout(() => {
                logEvent({
                    event: 'custom_event',
                    eventName,
                    label: '',
                    action: '',
                })
            }, timeout)
        })
    }

    return {
        logEvent,
        startPortalKeyEventsTracking,
        startExportKeyEventsTracking,
    }
})
