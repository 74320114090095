import { createI18n } from 'vue-i18n'

export default () =>
    createI18n({
        legacy: false,
        locale: 'en',
        fallbackLocale: 'en',
        // @ts-ignore TODO: i18n
        messages: {},
    })
