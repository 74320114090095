import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { useI18n } from 'vue-i18n'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'
import { commonNavigationTranslation, highlightedMenuTranslation } from '@/utils/translations/common-navigation'
import { ALL_GAMES_LINK, NAVIGATION_LINKS, BLOG_PATH, ALL_TAGS_LINK, ALL_SERIES_LINK } from '@/constants/general'
import { useRouter } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'
import { computed } from 'vue'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'


export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

const { t } = useI18n({
    messages: {
        en: {
            ...commonNavigationTranslation.en,
            ...highlightedMenuTranslation.en,
            copyright: '© Playgama, {year}',
        },
    },
})

const categoriesStore = useCategoriesStore()
const { menuCategoriesList, menuHighlightCategoriesList, randomGameHRU } = storeToRefs(categoriesStore)

const router = useRouter()

const randomGamePath = computed(
    () =>
        randomGameHRU.value &&
        router.resolve({ name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } }).path,
)

const isMounted = useIsMounted()

const copyright = t('copyright', { year: new Date().getFullYear() })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.footer)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.links_group, _ctx.$style.links_menu_highlight])
    }, [
      _createVNode(Typography, {
        is: "ul",
        type: "paragraph",
        size: "xs",
        class: _normalizeClass(_ctx.$style.links)
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuHighlightCategoriesList), (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.title
            }, [
              _createVNode(Link, {
                to: item.href,
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to", "class"])
            ]))
          }), 128)),
          (randomGamePath.value && _unref(isMounted))
            ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createVNode(Link, {
                  to: randomGamePath.value,
                  class: _normalizeClass(_ctx.$style.links_item),
                  color: "white-60",
                  onClick: _unref(categoriesStore).updateRandomGame
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('random')), 1)
                  ]),
                  _: 1
                }, 8, ["to", "class", "onClick"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createVNode(Link, {
              to: _unref(ALL_TAGS_LINK).link,
              class: _normalizeClass(_ctx.$style.links_item),
              color: "white-60"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)(_unref(ALL_TAGS_LINK).title)), 1)
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(Link, {
              to: _unref(ALL_SERIES_LINK).link,
              class: _normalizeClass(_ctx.$style.links_item),
              color: "white-60"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)(_unref(ALL_SERIES_LINK).title)), 1)
              ]),
              _: 1
            }, 8, ["to", "class"])
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.main_links)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.links_group, _ctx.$style.links_topic_categories])
      }, [
        _createVNode(Typography, {
          is: "ul",
          type: "paragraph",
          size: "xs",
          class: _normalizeClass(_ctx.$style.links)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCategoriesList), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.title
              }, [
                _createVNode(Link, {
                  to: item.href,
                  class: _normalizeClass(_ctx.$style.links_item),
                  color: "white-60"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"])
              ]))
            }), 128)),
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: _unref(ALL_GAMES_LINK).link,
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)(_unref(ALL_GAMES_LINK).title)), 1)
                ]),
                _: 1
              }, 8, ["to", "class"])
            ])
          ]),
          _: 1
        }, 8, ["class"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.links_group)
      }, [
        _createVNode(Typography, {
          is: "ul",
          type: "paragraph",
          size: "xs",
          class: _normalizeClass(_ctx.$style.links)
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: "/shop",
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('shop')), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: _unref(BLOG_PATH),
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60",
                external: "",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('blog')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NAVIGATION_LINKS), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.title
              }, [
                _createVNode(Link, {
                  to: item.link,
                  target: item.target,
                  class: _normalizeClass(_ctx.$style.links_item),
                  external: item.external,
                  color: "white-60"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)(item.title)), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "target", "class", "external"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ], 2),
    _createVNode(Typography, {
      is: "div",
      type: "paragraph",
      size: "xs",
      class: _normalizeClass(_ctx.$style.copyright)
    }, {
      default: _withCtx(() => [
        _createVNode(Link, {
          to: "/",
          class: _normalizeClass(_ctx.$style.links_item),
          color: "white-40"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(copyright)), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}
}

})