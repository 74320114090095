import type { BannerEventListenerArgs, BannerEventType } from '@/modules/adv/index'

type BannerOptions = {
    destroy?: () => void
}

export enum BannerState {
    'loading' = 'loading',
    'empty' = 'empty',
    'ready' = 'ready',
    'showing' = 'showing',
    'closed' = 'pending',
}
export class Banner {
    private readonly eventBus: HTMLElement

    private closed = false

    readonly destroy: () => void

    state: BannerState = BannerState.loading

    constructor(options: BannerOptions) {
        this.eventBus = document.createElement('b')
        this.destroy = options.destroy || (() => {})
    }

    triggerClosed() {
        if (this.state !== BannerState.closed) {
            this.state = BannerState.closed
            this.dispatch('closed')
        }
    }

    triggerRewarded(payload: unknown) {
        this.dispatch('rewarded', payload)
    }

    triggerEmpty() {
        this.state = BannerState.empty
        this.dispatch('empty')
    }

    triggerViewable() {
        this.dispatch('viewable')
    }

    triggerRendered() {
        this.state = BannerState.showing
        this.dispatch('rendered')
    }

    addEventListener(...args: BannerEventListenerArgs) {
        this.eventBus.addEventListener(...args)
    }

    removeEventListener(...args: BannerEventListenerArgs) {
        this.eventBus.removeEventListener(...args)
    }

    dispatch(event: BannerEventType, payload?: unknown) {
        this.eventBus.dispatchEvent(new CustomEvent<unknown>(event, { detail: payload }))
    }
}
