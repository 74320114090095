import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import { useLayoutStore } from '@/store/layout-store'
import { useLoggerStore } from '@/store/logger-store'
import Icon from '@/components_new/Icon/Icon.vue'
import Tooltip from '@/components_new/Tooltip.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import Button from './Button.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DesktopHeader',
  setup(__props) {

const loggerStore = useLoggerStore()

const { setCategoriesModalOpened } = useLayoutStore()

const { t } = useI18n({
    messages: {
        en: {
            openCategoriesBtnTitle: 'All games',
        },
    },
})

const categoriesStore = useCategoriesStore()

const { menuCategoriesList } = storeToRefs(categoriesStore)

function goToCategory() {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'navigate',
        label: 'categories_header',
        action: 'click',
    })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(_ctx.$style.header)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.scroller)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCategoriesList), (item) => {
        return (_openBlock(), _createBlock(Tooltip, {
          key: item.id,
          content: item.title,
          class: _normalizeClass(_ctx.$style.item)
        }, {
          default: _withCtx(() => [
            _createVNode(BaseLink, {
              tabindex: "-1",
              to: item.href,
              class: _normalizeClass(_ctx.$style.item_link),
              onClick: goToCategory
            }, {
              default: _withCtx(() => [
                _createVNode(Icon, {
                  name: item.id,
                  alt: item.title,
                  class: _normalizeClass(_ctx.$style.item_icon)
                }, null, 8, ["name", "alt", "class"])
              ]),
              _: 2
            }, 1032, ["to", "class"])
          ]),
          _: 2
        }, 1032, ["content", "class"]))
      }), 128))
    ], 2),
    _createVNode(Button, {
      color: "carbon-300",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(setCategoriesModalOpened)(true)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_unref(t)('openCategoriesBtnTitle')), 1)
      ]),
      _: 1
    })
  ], 2))
}
}

})